import styled from "styled-components";

export const IconWrapper = styled.figure`
	margin: 0;
	padding: 0;
	display: inline-block;
	line-height: 0;

	& > svg {
		height: ${(props) => props.size};
		width: ${(props) => props.size};
	}

	& > svg > path,
	rect {
		stroke: ${(props) => props.stroke};
		fill: ${(props) => props.fill};
		${({ strokeWidth }) => strokeWidth && `stroke-width: ${strokeWidth}`};
	}
`;
