import React from "react";
import loadable from "@loadable/component";

const Plus = loadable(() => import("./../../../assets/svg/16px/Plus.svg"));
const Minus = loadable(() => import("./../../../assets/svg/16px/Minus.svg"));
const CaretUp = loadable(
	() => import("./../../../assets/svg/16px/Caret_Up.svg"),
);
const CaretDown = loadable(
	() => import("./../../../assets/svg/16px/Caret_Down.svg"),
);
const Call = loadable(() => import("./../../../assets/svg/16px/Call.svg"));
const Polygon = loadable(
	() => import("./../../../assets/svg/16px/Polygon.svg"),
);
const Ellipse = loadable(
	() => import("./../../../assets/svg/64px/Ellipse.svg"),
);
const Avatar = loadable(() => import("./../../../assets/svg/16px/Avatar.svg"));
const Warning = loadable(
	() => import("./../../../assets/svg/16px/Warning.svg"),
);
const LongRightArrow = loadable(
	() => import("./../../../assets/svg/16px/Long_Right_Arrow.svg"),
);
const RightArrow = loadable(
	() => import("./../../../assets/svg/16px/Right_Arrow.svg"),
);
const LeftArrow = loadable(
	() => import("./../../../assets/svg/16px/Left_Arrow.svg"),
);
const DownArrow = loadable(
	() => import("./../../../assets/svg/16px/Down_Arrow.svg"),
);
const Email = loadable(() => import("./../../../assets/svg/16px/Email.svg"));
const XMark = loadable(() => import("./../../../assets/svg/16px/X_mark.svg"));
const Eye = loadable(() => import("./../../../assets/svg/24px/eye.svg"));
const EyeOff = loadable(() => import("./../../../assets/svg/24px/eye-off.svg"));
const CheckCircle = loadable(
	() => import("./../../../assets/svg/24px/Check_Circle.svg"),
);
const XMark24 = loadable(() => import("./../../../assets/svg/24px/X_mark.svg"));
const HandShake = loadable(
	() => import("./../../../assets/svg/24px/Graphic.svg"),
);
const Peers = loadable(() => import("./../../../assets/svg/24px/Peers.svg"));
const Search = loadable(() => import("./../../../assets/svg/24px/Search.svg"));
const Quote = loadable(() => import("./../../../assets/svg/32px/Default.svg"));
const Hamburger = loadable(
	() => import("./../../../assets/svg/32px/Hamburger.svg"),
);
const XMark32 = loadable(() => import("./../../../assets/svg/32px/X_Mark.svg"));
const CurriculumCustom = loadable(
	() => import("./../../../assets/svg/64px/Curriculum.svg"),
);
const FinancingCustom = loadable(
	() => import("./../../../assets/svg/64px/Financing.svg"),
);
const OnlineCustom = loadable(
	() => import("./../../../assets/svg/64px/Online.svg"),
);
const MonthlyCustom = loadable(
	() => import("./../../../assets/svg/64px/Monthly.svg"),
);
const UpfrontCustom = loadable(
	() => import("./../../../assets/svg/64px/Upfront.svg"),
);
const DeferredCustom = loadable(
	() => import("./../../../assets/svg/64px/Deferred.svg"),
);
const PeersCustom = loadable(
	() => import("./../../../assets/svg/64px/Peers.svg"),
);
const SupportCustom = loadable(
	() => import("./../../../assets/svg/support_custom.svg"),
);
const InstructionCustom = loadable(
	() => import("./../../../assets/svg/instruction_custom.svg"),
);
const LearnOnlineCustom = loadable(
	() => import("./../../../assets/svg/learn_online_custom.svg"),
);
const HandsOnProject = loadable(
	() => import("./../../../assets/svg/hands_on_learning_custom.svg"),
);
const PaperPlaneTilt = loadable(
	() => import("./../../../assets/svg/PaperPlaneTilt.svg"),
);
const Medal = loadable(() => import("./../../../assets/svg/Medal.svg"));
const PeerOutline = loadable(
	() => import("./../../../assets/svg/Peer_outline.svg"),
);
const ChalkBoardSimpleCustom = loadable(
	() => import("./../../../assets/svg/chalkBoardSimple_Custom.svg"),
);
const Calendar = loadable(() => import("./../../../assets/svg/calendar.svg"));
const Coding2 = loadable(() => import("./../../../assets/svg/coding1.svg"));
const Coding1 = loadable(() => import("./../../../assets/svg/coding2.svg"));
const CyberSecurity1 = loadable(
	() => import("./../../../assets/svg/cyberSecurity1.svg"),
);
const CyberSecurity2 = loadable(
	() => import("./../../../assets/svg/cyberSecurity2.svg"),
);
const DataAnalytics1 = loadable(
	() => import("./../../../assets/svg/dataAnalytics1.svg"),
);
const DataAnalytics2 = loadable(
	() => import("./../../../assets/svg/dataAnalytics2.svg"),
);
const DataScience1 = loadable(
	() => import("./../../../assets/svg/dataScience1.svg"),
);
const DataScience2 = loadable(
	() => import("./../../../assets/svg/dataScience2.svg"),
);
const Design1 = loadable(() => import("./../../../assets/svg/design1.svg"));
const Design2 = loadable(() => import("./../../../assets/svg/design2.svg"));
const TechSales1 = loadable(
	() => import("./../../../assets/svg/techSales1.svg"),
);
const TechSales2 = loadable(
	() => import("./../../../assets/svg/techSales1.svg"),
);
const Money = loadable(() => import("./../../../assets/svg/Money.svg"));
const Globe = loadable(() => import("./../../../assets/svg/Globe.svg"));
const Coaching = loadable(() => import("./../../../assets/svg/coaching.svg"));
const MachineLearning1 = loadable(
	() => import("./../../../assets/svg/machineLearning1.svg"),
);
const MachineLearning2 = loadable(
	() => import("./../../../assets/svg/machineLearning2.svg"),
);
const SBDark = loadable(
	() => import("./../../../assets/svg/springboard-logo-2023-dark-300x50.svg"),
);
const SBLight = loadable(
	() => import("./../../../assets/svg/springboard-logo-2023-light-300x50.svg"),
);
const LearningPathCheck = loadable(
	() => import("./../../../assets/svg/learning-path-check.svg"),
);
const Info = loadable(() => import("./../../../assets/svg/Info.svg"));

const SvgInlineIcon = ({ iconName }) => {
	const getIcon = () => {
		switch (iconName) {
			case "plus":
				return <Plus />;
			case "minus":
				return <Minus />;
			case "caretUp":
				return <CaretUp />;
			case "caretDown":
				return <CaretDown />;
			case "call":
				return <Call />;
			case "polygon":
				return <Polygon />;
			case "ellipse":
				return <Ellipse />;
			case "avatar":
				return <Avatar />;
			case "warning":
				return <Warning />;
			case "longRightArrow":
				return <LongRightArrow />;
			case "rightArrow":
				return <RightArrow />;
			case "leftArrow":
				return <LeftArrow />;
			case "downArrow":
				return <DownArrow />;
			case "email":
				return <Email />;
			case "xMark":
				return <XMark />;
			case "eye":
				return <Eye />;
			case "eyeOff":
				return <EyeOff />;
			case "checkCircle":
				return <CheckCircle />;
			case "xMark24":
				return <XMark24 />;
			case "handShake":
				return <HandShake />;
			case "peers":
				return <Peers />;
			case "search":
				return <Search />;
			case "quote":
				return <Quote />;
			case "hamburger":
				return <Hamburger />;
			case "xMark32":
				return <XMark32 />;
			case "curriculumCustom":
				return <CurriculumCustom />;
			case "financingCustom":
				return <FinancingCustom />;
			case "onlineCustom":
				return <OnlineCustom />;
			case "monthlyCustom":
				return <MonthlyCustom />;
			case "upfrontCustom":
				return <UpfrontCustom />;
			case "deferredCustom":
				return <DeferredCustom />;
			case "peersCustom":
				return <PeersCustom />;
			case "supportCustom":
				return <SupportCustom />;
			case "instructionCustom":
				return <InstructionCustom />;
			case "learnOnlineCustom":
				return <LearnOnlineCustom />;
			case "handsOnProject":
				return <HandsOnProject />;
			case "paperPlaneTilt":
				return <PaperPlaneTilt />;
			case "medal":
				return <Medal />;
			case "peerOutline":
				return <PeerOutline />;
			case "chalkBoardSimpleCustom":
				return <ChalkBoardSimpleCustom />;
			case "calendar":
				return <Calendar />;
			case "coding2":
				return <Coding2 />;
			case "coding1":
				return <Coding1 />;
			case "cyberSecurity1":
				return <CyberSecurity1 />;
			case "cyberSecurity2":
				return <CyberSecurity2 />;
			case "dataAnalytics1":
				return <DataAnalytics1 />;
			case "dataAnalytics2":
				return <DataAnalytics2 />;
			case "dataScience1":
				return <DataScience1 />;
			case "dataScience2":
				return <DataScience2 />;
			case "design1":
				return <Design1 />;
			case "design2":
				return <Design2 />;
			case "techSales1":
				return <TechSales1 />;
			case "techSales2":
				return <TechSales2 />;
			case "money":
				return <Money />;
			case "globe":
				return <Globe />;
			case "coaching":
				return <Coaching />;
			case "machineLearning1":
				return <MachineLearning1 />;
			case "machineLearning2":
				return <MachineLearning2 />;
			case "sb-dark":
				return <SBDark />;
			case "sb-light":
				return <SBLight />;
			case "learningPathCheck":
				return <LearningPathCheck />;
			case "info":
				return <Info />;
			default:
				return null;
		}
	};

	return getIcon();
};

export default SvgInlineIcon;
