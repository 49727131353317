import React from "react";
import PropTypes from "prop-types";
import { IconWrapper } from "./styles";
import SvgInlineIcon from "./SvgIcons";

const Icon = ({ iconName, stroke, fill, size, ...restProps }) => {
	return (
		<IconWrapper
			stroke={stroke}
			fill={fill}
			size={size}
			aria-hidden="true"
			{...restProps}
		>
			<SvgInlineIcon iconName={iconName}></SvgInlineIcon>
		</IconWrapper>
	);
};

Icon.propTypes = {
	iconName: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.string,
};

export default Icon;
